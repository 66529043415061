<template>
	<ion-page id="wawi">
		<PageHeader :title="'Waren-Wissen'"></PageHeader>

		<ion-content class="inset-container">
			<ion-toolbar>
				<ion-searchbar
					:value="searchInput"
					mode="ios"
					placeholder="Suche"
					@ionInput="searchInput = $event.target.value"
				></ion-searchbar>
			</ion-toolbar>
			<ion-list v-for="(value, name) in wawi" :key="name">
				<ion-list-header class="slabs">{{ name }}</ion-list-header>

				<ion-item
					v-for="item in value"
					:key="item.id"
					:detail="true"
					:router-link="`/wawi/${item.id}/${$slugify(item.name)}`"
				>
					<ion-avatar slot="start">
						<img :src="imgLink(item.icon)" />
					</ion-avatar>
					<ion-label>
						<h2 class="slabs">{{ item.name }}</h2>
						<p>{{ item.kategorie }}</p>
					</ion-label>
				</ion-item>
			</ion-list>
		</ion-content>
		<TabBar></TabBar>
	</ion-page>
</template>

<script>
import {
	IonContent,
	IonPage,
	IonList,
	IonListHeader,
	IonItem,
	IonAvatar,
	IonLabel,
	IonSearchbar,
	IonToolbar,
} from '@ionic/vue';

import TabBar from '@/components/TabBar.vue';
import PageHeader from '@/components/PageHeader.vue';

export default {
	name: 'WaWi',
	components: {
		IonContent,
		IonPage,
		TabBar,
		PageHeader,
		IonList,
		IonListHeader,
		IonItem,
		IonAvatar,
		IonLabel,
		IonSearchbar,
		IonToolbar,
	},
	data() {
		return {
			rawItems: [],
			wawi: {},
			searchInput: '',
		};
	},
	watch: {
		searchInput(newVal) {
			const filter = newVal.toLowerCase();
			const filteredArray = this.rawItems.filter(
				(obj) =>
					obj.name.toLowerCase().includes(filter) ||
					obj.kategorie.toLowerCase().includes(filter)
			);
			this.createSortedArray(filteredArray);
		},
	},
	methods: {
		createSortedArray(array) {
			let newObject = {};
			for (let item of array) {
				const key = item.name.charAt(0).toUpperCase();
				newObject[key] = newObject[key] || [];
				newObject[key].push(item);
			}
			this.wawi = newObject;
		},
		async ionViewWillEnter() {
			const { data } = await this.$http.get(
				'/items/warenwissen?fields=*&sort=name'
			);
			this.rawItems = data.data;
			this.createSortedArray(data.data);
		},
		imgLink(image) {
			return `${
				this.$store.state.imageBase
			}${image}?key=kursicon&access_token=${localStorage.getItem(
				'auth_token'
			)}`;
		},
		filterList(input) {
			console.log(input);
		},
	},
};
</script>

<style lang="scss" scoped>
#wawi {
	ion-content {
		--background: white;
	}
	ion-list-header,
	h2 {
		font-weight: bold;
		font-size: 17px;
		line-height: 22px;
		letter-spacing: 0.7px;
		color: #131313;
		margin: 0px;
	}

	ion-list-header {
		background: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.07);
	}

	ion-list:last-child {
		padding-bottom: 100px;
		background: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.07);
	}

	ion-item {
		cursor: pointer;
	}

	ion-toolbar {
		--min-height: 70px;
	}
	ion-searchbar {
		padding-top: 15px;
	}

	@media screen and (min-width: 992px) {
		ion-searchbar {
			padding-top: 20px;
			padding-left: 0px;
			padding-right: 0px;
		}
	}
}
</style>
